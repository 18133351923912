import axios from 'axios';
import { SIGNOUT_AUTH_SUCCESS } from 'types/actions/Auth.actions';

const jwtAxios = axios.create({
  //baseURL: 'https://crema-mongo-api.herokuapp.com/api/', //YOUR_API_URL HERE
  //baseURL: 'http://192.168.2.2:5000/api/', //YOUR_API_URL HERE
  baseURL: 'https://P1EDIV3-BastianSolutions-Test.azurewebsites.net/api/', //YOUR_API_URL HERE
  //baseURL: 'https://localhost:5001/api/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

export const jwtAxios_Interceptor = (store: any) => {
  jwtAxios.interceptors.response.use(
    res => res,
    err => {
      if (err.response.status === 401) {
        console.log('Need to logout user');
        setAuthToken(null);
        store.dispatch({ type: SIGNOUT_AUTH_SUCCESS });
      }
      return Promise.reject(err);
    },
  );
}
// ===========Move this to store/index.js as it can access to store object
// jwtAxios.interceptors.response.use(
//   res => res,
//   err => {
//     if (err.response.status === 401) {
//       console.log('Need to logout user');
//       setAuthToken(null);
//       store.dispatch({type: SIGNOUT_AUTH_SUCCESS});
//     }
//     return Promise.reject(err);
//   },
// );
// export const setAuthToken = (token: string | null) => {
//   if (token) {
//     jwtAxios.defaults.headers.common['x-auth-token'] = token;
//     localStorage.setItem('token', token);
//   } else {
//     delete jwtAxios.defaults.headers.common['x-auth-token'];
//     localStorage.removeItem('token');
//   }
// };

export const setAuthToken = (token: string | null) => {
  if (token) {
    jwtAxios.defaults.headers['Authorization'] = "Bearer " + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
