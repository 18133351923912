import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import { UPDATE_ENDPOINT_LIST } from '../../types/actions/Endpoint.action';

export const onRequestEndpoint = (body: {
  pageNumber: number;
  pageSize: number;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('Endpoints/Basic?pageNumber='+body.pageNumber+"&pageSize="+body.pageSize);     
      dispatch(fetchSuccess());
      const items = res.data.result; // extract the notification items from the response data
      const pageIndex = 0; // set the current page index to 0
      const totalPages = 1; // set the total number of pages to 1
      const pageNumber = 1; // set the current page number to 1
      const pageSize = body.pageSize; // set the page size from the input parameter
      const totalCount = items.length; // set the total number of notifications to the number of items in the response

      const transformedData = { // create a new object with the transformed data
        items,
        pageIndex,
        totalPages,
        pageNumber,
        pageSize,
        totalCount
      };
      console.log('Endpoint Response Data:', res.data);
      console.log('Endpoint Response Data Result:', res.data.result);
      console.log('Endpoint Data 1:', res.data.result[0]);
      console.log('Endpoint Transformed Data:', transformedData);
      dispatch({
        type: UPDATE_ENDPOINT_LIST, 
        payload: transformedData,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};
